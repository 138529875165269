body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-0{ margin-top: 0px!important; margin-bottom: 0px!important }
.mx-0{ margin-left: 0px!important; margin-right: 0px!important;  }
.my-1{ margin-top: 5px!important; margin-bottom: 5px!important }
.mx-1{ margin-left: 5px!important; margin-right: 5px!important;  }
.my-2{ margin-top: 10px!important; margin-bottom: 10px!important }  
.mx-2{ margin-left: 10px!important; margin-right: 10px!important;  }
.my-3{ margin-top: 15px!important; margin-bottom: 15px!important }
.mx-3{ margin-left: 15px!important; margin-right: 15px!important;  }
.my-4{ margin-top: 20px!important; margin-bottom: 20px!important }
.mx-4{ margin-left: 20px!important; margin-right: 20px!important;  }

.py-0{ padding-top: 0px!important; padding-bottom: 0px!important }
.py-1{ padding-top: 5px!important; padding-bottom: 5px!important }
.py-2{ padding-top: 10px!important; padding-bottom: 10px!important }
.px-0{ padding-left: 0px!important; padding-right: 0px!important;  }

.mt-0{ margin-top: 0px!important }
.mt-1{ margin-top: 5px!important }
.mt-2{ margin-top: 10px!important }
.mt-3{ margin-top: 15px!important }
.mt-4{ margin-top: 20px!important }
.mt-5{ margin-top: 25px!important }
.mb-0{ margin-bottom: 0px!important }
.mb-1{ margin-bottom: 5px!important }
.mb-2{ margin-bottom: 10px!important }
.mb-3{ margin-bottom: 15px!important }
.mb-4{ margin-bottom: 20px!important } 
.mb-5{ margin-bottom: 25px!important }
.ml-0{ margin-left: 0px!important }
.ml-1{ margin-left: 5px!important }
.ml-2{ margin-left: 10px!important }
.ml-3{ margin-left: 15px!important }
.ml-4{ margin-left: 20px!important } 
.ml-5{ margin-left: 25px!important }
.pt-0{ padding-top: 0px!important }
.pt-1{ padding-top: 5px!important }
.pt-2{ padding-top: 10px!important }
.pt-3{ padding-top: 15px!important }
.pt-4{ padding-top: 20px!important }
.pt-5{ padding-top: 25px!important }
.pb-0{ padding-bottom: 0px!important }
.pb-1{ padding-bottom: 5px!important }
.pb-2{ padding-bottom: 10px!important }
.pb-3{ padding-bottom: 15px!important } 
.pb-4{ padding-bottom: 20px!important } 
.pb-5{ padding-bottom: 25px!important }
.pr-0{ padding-right: 0px!important }
.pr-1{ padding-right: 5px!important }
.pr-2{ padding-right: 10px!important }
.pr-3{ padding-right: 15px!important }
.pr-4{ padding-right: 20px!important }
.pr-5{ padding-right: 25px!important }
.pl-0{ padding-left: 0px!important }
.pl-1{ padding-left: 5px!important }
.pl-2{ padding-left: 10px!important }
.pl-3{ padding-left: 15px!important } 
.pl-4{ padding-left: 20px!important } 
.pl-5{ padding-left: 25px!important }

.relative { position: relative; }
.clear { clear: both; }

.bold { font-weight: bold; }

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}